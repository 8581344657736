



















import { Prop, Component, Mixins } from 'vue-property-decorator'

import { BeatImageOrProducerLogo } from '~/mixins/beat-image-or-producer-logo'
import { CartStore } from '~/store/cart'

@Component
export default class CartItemBeat extends Mixins(BeatImageOrProducerLogo) {
    @Prop({ type: Boolean }) isCart: boolean
    @Prop({ type: Object }) cartItem: ICartItem
    @Prop({ type: Number }) boughtLicensePrice: number

    get calculatedPrice() {
        let price
        // if it's an exlusive, select the price added by the producer inside the beat (if there is one) otherwise use default exclusive price
        if (this.cartItem.selected_license_option.is_exclusive && this.cartItem.beat.price_for_exclusive) price = this.cartItem.beat.price_for_exclusive
        else price = this.cartItem.selected_license_option.price
        return Math.max(price - (this.boughtLicensePrice || 0), 0)
    }

    @CartStore.Action('removeItemFromCart') removeItemFromCart: (cartItem: ICartItem) => void
}
