














import { Prop, Component, Mixins } from 'vue-property-decorator'

import { BeatImageOrProducerLogo } from '~/mixins/beat-image-or-producer-logo'
import { CartStore } from '~/store/cart'

@Component
export default class CartItemBeat extends Mixins(BeatImageOrProducerLogo) {
    @Prop({ type: Boolean }) isCart: boolean
    @Prop({ type: Object }) cartItem: ICartItem

    @CartStore.Action('removeItemFromCart') removeItemFromCart: (cartItem: ICartItem) => void
}
