





























import { Component, Vue } from 'vue-property-decorator'

import { analyticsPageView } from '~/services/analytics'
import { ModalStore, ModalType } from '~/store/modal'
import CartItemBeat from '~/components/CartItemBeat.vue'
import CartItemSoundKit from '~/components/CartItemSoundKit.vue'
import CartHeading from '@/components/cart/CartHeading.vue'
import { CartStep, CartStore } from '~/store/cart'
import { AuthStore } from '~/store/auth'

@Component({ components: { CartHeading, CartItemBeat, CartItemSoundKit } })
export default class CartReview extends Vue {
    CartStep = CartStep
    ModalType = ModalType

    @CartStore.State('coupons') coupons: any
    @CartStore.Getter('beatCartItems') beatCartItems: ICartItem[]
    @CartStore.Getter('soundKitCartItems') soundKitCartItems: ICartItem[]
    @CartStore.Getter('cartTotalAmount') cartTotalAmount: number
    @AuthStore.Getter('isAuthenticated') isAuthenticated: boolean

    @CartStore.Mutation('SET_CART_STEP') SET_CART_STEP: (cartStep: CartStep) => void
    @CartStore.Mutation('ADD_COUPON') ADD_COUPON: () => void
    @CartStore.Action('fetchCartItems') fetchCartItems: () => void
    @ModalStore.Mutation('SET_ACTIVE_MODAL') SET_ACTIVE_MODAL: () => void

    created() {
        this.fetchCartItems()
        // send page view event to GA for cart review
        analyticsPageView({ page: 'cart/review', title: 'Cart Review' })
    }
}
